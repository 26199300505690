import * as React from "react";
import styles from "./footer.component.module.scss";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as LinkedIn } from "../../../assets/svg/businessLinkedin.svg";
import { ReactComponent as Facebook } from "../../../assets/svg/businessFacebook.svg";
import { ReactComponent as XIcon } from "../../../assets/svg/businessX.svg";
import { ReactComponent as Instagram } from "../../../assets/svg/businessInstagram.svg";
import { ReactComponent as Youtube } from "../../../assets/svg/businessYoutube.svg";
// import { ReactComponent as Github } from "../../../assets/svg/businessGithub.svg";
import { ReactComponent as KeepPrivateLogo } from '../../../assets/svg/keep_private_logo.svg'
import { ROUTES } from '../../constants/routes';
import { DEVICE } from "../../constants/constants";
import { useDeviceStore } from '../../../StateManager/DeviceStore'
import ZirohLogo from '../../../assets/webp/ziroh_logo_white.webp'


export function Footer() {
    const { device, setDevice } = useDeviceStore();

    return (
        <div className={styles.footer}>
            <div className={styles.footerHeader}>
                <div className={styles.headerLogoContainer}>
                    <img src={ZirohLogo} className={styles.zirohLogo}/>
                </div>
                <div className={styles.keepPrivateLogoContainer}>
                    <KeepPrivateLogo className={styles.keepPrivateLogo} />
                </div>
            </div>
            <div className={styles.linksAndMediaContainer}>
                <div className={styles.linksContainer}>
                    <div className={styles.linksSectionContainer}>
                        <div className={styles.linksChildContainer}>
                            <p className={styles.linksHeader}>Individuals</p>
                            <Link reloadDocument to={ROUTES.PRODUCTS.DRIVE} target="_blank">Zunu Drive</Link>

                            <Link reloadDocument to={ROUTES.PRODUCTS.MAIL} target="_blank">Zunu Mail</Link>

                            <Link reloadDocument to={ROUTES.PRODUCTS.MESSAGE} target="_blank">Zunu Message</Link>

                            <Link reloadDocument to={ROUTES.PRODUCTS.CAMERA} target="_blank"> Zunu Camera</Link>

                            <Link reloadDocument to={ROUTES.PRODUCTS.PORTABLE} target="_blank">Zunu Portable</Link>
                        </div>
                    </div>
                    <div className={styles.linksSectionContainer}>
                        <div className={styles.linksChildContainer}>
                            <p className={styles.linksHeader}> Enterprise</p>
                            <Link reloadDocument to={ROUTES.BUSINESS_PRODUCTS.SECURE_STRING} target="_blank">Zunu Secure String</Link>

                            <Link reloadDocument to={ROUTES.BUSINESS_PRODUCTS.SECURE_NUMERIC} target="_blank">Zunu Secure Numeric</Link>

                            <Link reloadDocument to={ROUTES.BUSINESS_PRODUCTS.SECURE_DS} target="_blank">Zunu SecureDS</Link>

                            <Link reloadDocument to={ROUTES.BUSINESS_PRODUCTS.ZUNU_DATABSE} target="_blank">Zunu Database</Link>

                            <Link reloadDocument to={ROUTES.BUSINESS_PRODUCTS.MESSAGING_GATEWAY} target="_blank">Messaging Gateway</Link>

                            <Link reloadDocument to={ROUTES.BUSINESS_PRODUCTS.PLATFORM_SDKS} target="_blank">Zunu Platform SDKs</Link>
                        </div>
                    </div>
                    {/* <div className={styles.linksSectionContainer}>
                        <div className={styles.linksChildContainer}>
                            <p className={styles.linksHeader}>Zunu Infinity</p>
                            <Link reloadDocument to={""}>Zunu Infinity</Link>
                            {device === DEVICE.MOBILE &&
                                <div className={styles.linksSectionContainer}>
                                    <div className={styles.linksChildContainer}>
                                        <p className={styles.linksHeader}>Resources</p>
                                        <Link reloadDocument to={""}>Blogs</Link>

                                        <Link reloadDocument to={""}>Whitepapers</Link>
                                    </div>
                                </div>
                            }

                        </div>
                    </div> */}
                    {/* {device !== DEVICE.MOBILE &&
                        <div className={styles.linksSectionContainer}>
                            <div className={styles.linksChildContainer}>
                                <p className={styles.linksHeader}>Resources</p>
                                <Link reloadDocument to={""}>Blogs</Link>

                                <Link reloadDocument to={""}>Whitepapers</Link>
                            </div>
                        </div>
                    } */}
                    <div className={styles.linksSectionContainer}>
                        <div className={styles.linksChildContainer}>
                            <p className={styles.linksHeader}>More</p>

                            {/* <Link reloadDocument to={ROUTES.ABOUT_US}>About Us</Link> */}

                            <Link reloadDocument to={ROUTES.ACADEMIC_ALLIANCE} target="_blank">Academic Alliance</Link>

                            <Link reloadDocument to={ROUTES.CAREERS} target="_blank">Careers</Link>

                            {/* <Link reloadDocument to={ROUTES.SUPPORT} target="_blank">Support</Link> */}

                            {/* <Link reloadDocument to={ROUTES.CONTACT_US} target="_blank">Contact Us</Link> */}
                        </div>
                    </div>
                </div>


                <hr className={styles.horizontalLine}></hr>
                <div className={styles.privacyMediaContainer}>
                    <p className={styles.copyright}>© 2024 Ziroh Labs. All rights reserved.</p>

                    <div className={styles.mediaIconContainer}>
                        <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.FACEBOOK} target="_blank" aria-label="Facebook"><Facebook className={styles.mediaIcons} /></Link>
                        <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.LINKEDIN_BUSINESS} target="_blank" aria-label="LinkedIn"><LinkedIn className={styles.mediaIcons} /></Link>
                        <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.INSTAGRAM} target="_blank" aria-label="Instagram"><Instagram className={styles.mediaIcons} /></Link>
                        {/* <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.X} target="_blank" aria-label="X"><Github className={styles.mediaIcons} /></Link> */}
                        <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.YOUTUBE} target="_blank" aria-label="Youtube"> <Youtube className={styles.mediaIcons} /></Link>
                        <Link reloadDocument to={ROUTES.SOCIAL_MEDIA.X} target="_blank" aria-label="X"><XIcon className={styles.mediaIcons} /></Link>
                    </div>

                </div>

            </div>
        </div>
    );
}
