import React, { useState } from 'react'
import styles from './aboutUs.module.scss'
import { ReactComponent as LinkedInIcon } from '../../assets/svg/linkedIn_icon.svg'
import { ReactComponent as WikipediaIcon } from '../../assets/svg/wikipedia_icon.svg'
import { Link } from 'react-router-dom'
import { ReactComponent as UserIcon } from '../../assets/svg/whiteUser.svg'
import { DEVICE } from '../../_shared/constants/constants'
import { useDeviceStore } from '../../StateManager/DeviceStore'


interface Recognition {
    text: string;
}

interface CardsData {
    image: any,
    name: string,
    linkedIn?: string,
    wikipedia?: string,
    type?: string,
    Recognition?: Recognition[],
}



function TeamCard({ name, image, linkedIn, wikipedia, type, Recognition }: CardsData) {

    const { device } = useDeviceStore();
    const [user, setIsUser] = useState(false);

    return (
        <div className={styles.memberDetails}>
            <div className={styles.memberImgContainer}>
                <img src={image} className={styles.memberImg} />
                {device == DEVICE.MOBILE && Recognition &&
                    <div className={styles.userIconContainer}>
                        <UserIcon className={styles.userIcon} />
                    </div>
                }
                {Recognition &&
                    <div className={styles.overlay}>
                        <div className={styles.overlayTextContainer}>
                            {/* <UserIcon className={styles.userIcon} /> */}
                            {Recognition?.map((data: any) => {
                                return (
                                    <p className={styles.overlayText}>{data.text}</p>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
            <div className={styles.nameLinksContainer}>
                <p className={styles.memberName}>{name}</p>
                <div className={styles.memberLinksContainer}>
                    {(linkedIn!) &&
                        <Link to={linkedIn} target='_blank'><LinkedInIcon className={styles.linkedInIcon} /></Link>
                    }
                    {(wikipedia!) &&
                        <Link to={wikipedia} target='_blank'><WikipediaIcon className={styles.linkedInIcon} /></Link>

                    }
                </div>
            </div>
        </div>
    )


}

export default TeamCard