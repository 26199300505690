import React, { useState } from 'react'
import styles from './aboutUs.module.scss'
import { ADVISORY_TEAM, AWARDS, EXECUTIVE_TEAM, GARNTER_CARD } from './aboutUsData'
import { Link } from 'react-router-dom'
import TeamCard from './teamCard.component'
import Nasscom from '../../assets/webp/nascom.webp'
import DSCI from '../../assets/webp/dsci.webp'
import { ReactComponent as Redirect } from '../../assets/svg/redirect_icon.svg'
import { ReactComponent as Business_Standard } from '../../assets/svg/business_standard.svg'
import USA from '../../assets/webp/usa.webp'
import India from '../../assets/webp/India.webp'
import Australia from '../../assets/webp/Australia.webp'
import { motion, AnimatePresence, easeOut } from 'framer-motion'
import { Helmet } from 'react-helmet'
import AboutUsCard from './aboutusCard.component'
import { ROUTES } from '../../_shared/constants/routes'

function AboutUs() {


    const [isReadMore, setIsReadMore] = useState(true);
    const [isOurStory, setIsOurStory] = useState(false);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore)
    }
    const toggleOurStory = () => {
        setIsOurStory(!isOurStory)
    }

    return (
        <div className={styles.aboutUsPage} >
            {/* <Helmet>
                <meta name="description" content="About us" />
                <title>About us</title>
                <meta property="og:site_name" content="Zunu" />
                <meta property="og:title" content="Ziroh - About us" />
                <meta property="og:url" content="https://ziroh.com/about-us" />
                <meta property="og:description" content="About us" />
                <meta property="og:type" content="website" />
                <meta property="og:image"
                    content="https://ziroh.com/Images/zunuimages/zunu_icon_colored-192x192.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:site" content="@zirohlabs" />
                <meta name="twitter:title" content="Zunu: Software-defined security stack with privacy-preserving, performant cryptographic algorithms." />
                <meta name="twitter:description" content="ziroh" />
                <meta name="twitter:image"
                    content="https://ziroh.com/Images/zunuimages/zunu_icon_colored-192x192.png" />
            </Helmet> */}
            <div className={styles.headerContainer}
            >
                <div className={styles.headerChildContainer}>
                    <div className={styles.header}>
                        <p className={styles.heading}>Meet Ziroh Labs</p>
                        <p className={styles.subHeading}>
                            We aspire to a future where data privacy and AI innovation go hand in hand. From privacy-first applications to Zunu Infinity, our next-gen AI platform that makes high-performance AI accessible and affordable, we're committed to protecting privacy while empowering everyone to unlock AI's potential securely.
                        </p>
                    </div>
                </div>
            </div>

            <div className={styles.aboutUsChildContainer}>
                <AnimatePresence>
                    <motion.div className={styles.ourStoryContainer}>
                        <p className={styles.ourStoryTitle}>Our Story</p>

                        <motion.div className={styles.ourStoryContent}>
                            <p className={styles.heading}>Founded in August 2016, Ziroh Labs has been at the forefront of Privacy & AI  innovation. </p>
                            <motion.p className={styles.body}
                            >
                                Our offerings range from advanced privacy solutions to pioneering a next-gen AI platform, called Zunu Infinity, to democratize AI by enabling high-performance model building on everyday CPUs.
                                <br></br>
                                With a commitment to privacy-first technology, our patented cryptographic and distributed system techniques safeguard data across any device and cloud infrastructure, ensuring that sensitive information remains secure at all times.
                                <br></br>

                                <motion.p
                                    initial={{ clipPath: 'inset(0% 0% 100% 0%)' }}
                                    animate={{
                                        clipPath: isOurStory ? 'inset(0% 0% 0% 0%)' : 'inset(0% 0% 100% 0%)',
                                        display: isOurStory ? "flex" : "none"
                                    }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <br></br>
                                    <span> Our product, Zunu, introduces the world’s first Privacy-Preserving and Portable Distributed File System, where data remains encrypted across storage layers, thanks to our proprietary computational unit that eliminates the need for decryption. With a USPTO-granted patent, Ziroh Labs empowers organizations to transform into secure digital enterprises, while individual users benefit from the same high level of privacy and data protection.
                                    </span>
                                </motion.p>

                            </motion.p>
                        </motion.div>

                        <motion.div className={styles.readMoreContainer}>
                            <motion.button className={styles.readMoreBtn} onClick={toggleOurStory}> {isOurStory ? 'Read less' : 'Read more'} </motion.button>
                        </motion.div>
                    </motion.div>
                </AnimatePresence>
                <div className={styles.ourStoryContainer}>
                    <p className={styles.ourStoryTitle}>Our Team </p>
                    <div className={styles.ourStoryContent}>
                        <p className={styles.heading}>Our team includes academicians from top institutions with decades of experience in cryptographic algorithms and protocol design.
                        </p>
                        <p className={styles.body}>Our technical board is honoured to include Dr Whit Diffie, a Turing Award winner and a pioneering figure in cryptography.
                            <br></br>
                            Alongside this, we have a passionate team of professionals dedicated to building software that protects the privacy of individuals and organisations.</p>
                    </div>
                    <div className={styles.readMoreContainer}>
                        <Link to={ROUTES.CAREERS} reloadDocument target='_blank'>
                            <button className={styles.readMoreBtn}>Join our Team</button>
                        </Link>
                    </div>
                </div>

                <div className={styles.advisoryTeamContainer}>
                    <p className={styles.advisoryTeamHeader}>Our Advisory Team </p>
                    <div className={styles.advisoryTeam}>
                        {ADVISORY_TEAM.map(({ image, name, linkedIn, wikipedia, recognition }) => {
                            return (
                                <TeamCard
                                    image={image}
                                    name={name}
                                    linkedIn={linkedIn}
                                    wikipedia={wikipedia}
                                    Recognition={recognition}
                                />
                            )
                        })
                        }
                    </div>
                </div>
                <div className={styles.advisoryTeamContainer}>
                    <p className={styles.advisoryTeamHeader}>Our Executive Team </p>
                    <div className={styles.advisoryTeam}>
                        {EXECUTIVE_TEAM.map(({ image, name, linkedIn, recognition }) => {
                            return (
                                <TeamCard
                                    image={image}
                                    name={name}
                                    linkedIn={linkedIn}
                                    type="secondary"
                                    Recognition={recognition}
                                />
                            )
                        })
                        }
                    </div>
                </div>

                <div className={styles.gartnerRecognitionContainer}>
                    <p className={styles.sectionHeading}>Gartner Recognitions</p>
                    <div className={styles.gartnerCardContainer}>
                        {GARNTER_CARD.map(({ year, gartnerText, categories }) => {
                            return (
                                <AboutUsCard
                                    year={year}
                                    gartnerText={gartnerText}
                                    Categories={categories}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className={styles.awardsContainer}>
                    <p className={styles.awardsTitle}>
                        Awards & Accolades
                    </p>
                    <div className={styles.awardsGridContainer}>
                        {AWARDS.map((data) => {
                            return (
                                <div className={styles.awardsContent}>
                                    <data.icon className={styles.awardsIcon} />
                                    <div className={styles.awardsBodyContainer}>
                                        <p className={styles.awardsHeading}>
                                            {data.heading}
                                        </p>
                                        <p className={styles.awardsBody}>
                                            {data.id == "4" ?
                                                <span>
                                                    {isReadMore ? data.body.slice(0, 175) : data.body}{isReadMore && <span>... </span>}
                                                    {data.body.length > 175 &&

                                                        <span className={styles.showless} onClick={toggleReadMore}>
                                                            {isReadMore ? 'read more' : 'read less'}
                                                        </span>

                                                    }
                                                </span> :
                                                <span>
                                                    {data.body}
                                                </span>
                                            }
                                        </p>
                                    </div>
                                </div>
                            )
                        })

                        }
                    </div>
                </div>

                <div className={styles.membershipsContainer}>
                    <p className={styles.sectionHeading}>
                        Memberships
                    </p>
                    <div className={styles.membershipContent}>
                        <div className={styles.membershipCard}>
                            <div className={styles.membershipImg}>
                                <img src={Nasscom}
                                    className={styles.membershipLogo} />
                            </div>
                            <div className={styles.memberBody}>
                                <p className={styles.membershipHeader}>Member of NASSCOM Deep Tech Club</p>
                                <p className={styles.membershipBody}>We are among the earliest companies selected by NASSCOM to join its prestigious DeepTech Club.</p>
                            </div>
                        </div>
                        <div className={styles.membershipCard}>
                            <div className={styles.membershipImg}>
                                < img src={DSCI}
                                    className={styles.membershipLogo} />
                            </div>
                            <div className={styles.memberBody}>
                                <p className={styles.membershipHeader}>Member of DSCI</p>
                                <p className={styles.membershipBody}>We are added as a Case Study for the Data Privacy Officer course</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className={styles.socialEngagementsContainer}>
                    <p className={styles.sectionHeading}>
                        Social Engagements
                    </p>
                    <div className={styles.engagementChildContainer}>
                        <div className={styles.engagementImgContainer}>
                            <Business_Standard className={styles.business_Standard} />
                        </div>
                        <div className={styles.engagementBodyContainer}>
                            <p className={styles.engagementContent}>Ziroh Labs Pioneers Privacy with South India's 1st Ever Privacy Lab with Guru Nanak Institutions</p>
                            <div className={styles.exploreBtnContainer}>
                                <Link to={"https://www.business-standard.com/content/press-releases-ani/ziroh-labs-pioneers-privacy-with-south-india-s-1st-ever-privacy-lab-with-guru-nanak-institutions-123121900375_1.html"} target='_blank'>
                                    <button className={styles.exploreBtn}>Explore <Redirect className={styles.redirectIcon} /></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className={styles.pressenceContainer}>
                    <p className={styles.sectionHeading}>Our Presence</p>
                    <div className={styles.pressenceGridContainer}>
                        <div className={styles.country_Container}>
                            <img src={USA} className={styles.countries} />
                            <div className={styles.countryNameContainer}>
                                <p className={styles.countryName}>USA</p>
                            </div>
                        </div>
                        <div className={styles.country_Container}>
                            <img src={India} className={styles.countries} />
                            <div className={styles.countryNameContainer}>
                                <p className={styles.countryName}>India</p>
                            </div>
                        </div>
                        <div className={styles.country_Container}>
                            <img src={Australia} className={styles.countries} />
                            <div className={styles.countryNameContainer}>
                                <p className={styles.countryName}>Australia</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div >
    )
}

export default AboutUs