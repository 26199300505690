import Whitfield_diffie from '../../assets/webp/advisory_team/whitfield_diffie.webp'
import Scott_McNealy from '../../assets/webp/advisory_team/Scott_McNealy.webp'
import William_Raduchel from '../../assets/webp/advisory_team/William_Raduchel.webp'
import Raj_Singh from '../../assets/webp/advisory_team/Raj_Singh.webp'
import Madhavan from '../../assets/webp/advisory_team/Madhavan.webp'
import Sadagopan from '../../assets/webp/advisory_team/Sadagopan.webp'
import Jan_Uddenfeldt from '../../assets/webp/advisory_team/Jan_Uddenfeldt.webp'
import WK_Choi from '../../assets/webp/advisory_team/WK_Choi.webp'
import Humphrey_Polanen from '../../assets/webp/advisory_team/Humphrey_Polanen.webp'
import Jeffrey_Brown from '../../assets/webp/advisory_team/Jeffrey_Brown.webp'
import Debanga_Neog from '../../assets/webp/advisory_team/Debanga_Neog.webp'
import Rekha_Ravindra from '../../assets/webp/advisory_team/Rekha_Ravindra.webp'
import Sudhir_Ispahani from '../../assets/webp/advisory_team/Sudhir_Ispahani.webp'
import Bob_Mansfield from '../../assets/webp/advisory_team/Bob_Mansfield.webp'

import Hrishikesh_Dewan from '../../assets/webp/executive_team/Hrishikesh_Dewan.webp'
import Igneta_DSouza from '../../assets/webp/executive_team/Igneta_DSouza.webp'
import Aninda_Sen from '../../assets/webp/executive_team/Aninda_Sen.webp'
import Ajay_Goel from '../../assets/webp/executive_team/Ajay_Goel.webp'
import Surabhi_Das from '../../assets/webp/executive_team/Surabhi_Das.webp'
import Herman_Collins from '../../assets/webp/executive_team/Herman_Collins.webp'

import { ReactComponent as CiscoIcon } from '../../assets/svg/cisco_LaunchPad.svg'
import { ReactComponent as SamsungIcon } from '../../assets/svg/samsung_accelerator.svg'
import { ReactComponent as Mass_challengeIcon } from '../../assets/svg/masss_challenge.svg'
import { ReactComponent as IEEE_icon } from '../../assets/svg/ieee.svg'


export const ADVISORY_TEAM = [
    {
        name: "Dr. Whitfield Diffie, Chief Technologist Officer.",
        image: Whitfield_diffie,
        wikipedia: "https://en.wikipedia.org/wiki/Whitfield_Diffie",
        recognition: [
            {
                text: "Turing Award Winner in Cryptography"
            },
            {
                text: "Member of The Royal Society"
            },
            {
                text: "Former Sun Fellow at Sun Microsystems"
            },
            {
                text: "Consulting Scholar at Stanford University"
            },
        ],

    },
    {
        name: "Scott McNealy, Advisor, Strategy",
        image: Scott_McNealy,
        linkedIn: "https://www.linkedin.com/in/smcnealy/",
        wikipedia: "https://en.wikipedia.org/wiki/Scott_McNealy",
        recognition: [
            {
                text: "Founder & Chairman of Sun Microsystems"
            },
            {
                text: "Founder of Curriki"
            },
        ],
    },
    {
        name: "William Raduchel, Chairman",
        image: William_Raduchel,
        linkedIn: "https://www.linkedin.com/in/wjraduchel/",
        wikipedia: "https://en.wikipedia.org/wiki/William_Raduchel",
        recognition: [
            {
                text: "Former Chief Strategy Officer at Sun Microsystems"
            },
            {
                text: "Chairman of Ruckus Networks"
            },
            {
                text: "Former Chief Technology Officer at AOL Time Warner"
            },
            {
                text: "Former Faculty Member in Economics at Harvard University"
            },
        ],
    },
    {
        name: "Raj Singh, Board Observer",
        image: Raj_Singh,

        recognition: [
            {
                text: "APIGee, Founder, Acquired by Google"
            },
            {
                text: "Cerent, Founder"
            },
            {
                text: "Stratomone Founder"
            },
        ],
    },
    {
        name: "Prof. S Sadagopan, Advisor Strategy",
        image: Sadagopan,
        linkedIn: "https://www.linkedin.com/in/sadagopan/",
        recognition: [
            {
                text: "Ex-Director IIIT Bangalore"
            },
        ],

    },
    {
        name: "Sudhir Ispahani, Advisor Strategy",
        image: Sudhir_Ispahani,
        linkedIn: "https://www.linkedin.com/in/sudhir-ispahani-0118075/",
        recognition: [
            {
                text: "Chief Technology Officer and Chairman of the Technology Board of Chello Broadband, United Global Communications."
            },
        ],

    },
    {
        name: "Jan Uddenfeldt, Advisor, Strategy",
        image: Jan_Uddenfeldt,
        linkedIn: "https://www.linkedin.com/in/jan-uddenfeldt-4b7379/",
        recognition: [
            {
                text: "Principal innovator and creator of GSM technology and mobile broadband 3G and 4G LTE technology. "
            },
            {
                text: "Former global Chief Technology Officer of Ericsson and Sony Mobile Communications."
            },
        ]
    },
    {
        name: "Humphrey Polanen, Advisor",
        image: Humphrey_Polanen,
        linkedIn: "https://www.linkedin.com/in/polanen/",
        recognition: [
            {
                text: "Founder and CEO of Cryptic Labs"
            },
        ]
    },
    {
        name: "Jeffrey Brown, Advisor, Strategy",
        image: Jeffrey_Brown,
        linkedIn: "https://www.linkedin.com/in/jeffrey-s-brown/",
    },
    {
        name: "Prof. Debanga Neog",
        image: Debanga_Neog,
        linkedIn: "https://www.linkedin.com/in/debanga/",
    },
    {
        name: "Rekha Ravindra, GTM, USA",
        image: Rekha_Ravindra,
        linkedIn: "https://www.linkedin.com/in/rekharavindra/",
    },
    {
        name: "WK Choi",
        image: WK_Choi,
        linkedIn: "https://www.linkedin.com/in/wk-choi-b6909b20/",
        recognition: [
            {
                text: "Founder, Chairman of BISTelligence"
            },
        ]
    },
    {
        name: "Bob Mansfield",
        image: Bob_Mansfield,
    },

]

export const EXECUTIVE_TEAM = [
    {
        name: "Hrishikesh Dewan, President & CEO, Ziroh Labs",
        image: Hrishikesh_Dewan,
        linkedIn: "https://www.linkedin.com/in/hrishikeshdewan/",
        recognition: [
            {
                text: "Founder and CEO of Ziroh Labs"
            },
            {
                text: "Technology Lead at Siemens, Distributed Systems and Industrial Cryptography Lab"
            },
            {
                text: "PhD in designing large-scale distributed file storage system that preserves privacy from the Indian Institute of Science"
            },
        ],
    },
    {
        name: "Aninda Sen,Co-Founder, Sr. VP, Business Development,  ANZ",
        image: Aninda_Sen,
        linkedIn: "https://www.linkedin.com/in/senaninda/",
        recognition: [
            {
                text: "Govt. of Australia, Program Architect, Home Affairs"
            },
        ]
    },

    {
        name: "Igneta DSouza, Co-Founder, Chief Product Officer",
        image: Igneta_DSouza,
        linkedIn: "https://www.linkedin.com/in/igneta-dsouza-63464b159/",

    },
    {
        name: "Ajay Goel, Sr VP,  Strategy",
        image: Ajay_Goel,
        linkedIn: "https://www.linkedin.com/in/ajay-goel-55b6b151/",
        recognition: [
            {
                text: "Symantec India, Managing Director"
            },
            {
                text: "Sun Microsystems"
            },
            {
                text: "Country Director of India"
            },
        ],
    },
    {
        name: "Surabhi Das, Legal Head, Ziroh Labs",
        image: Surabhi_Das,
        linkedIn: "https://www.linkedin.com/in/surabhi-das-8a3b7ba5/",
    },
    {
        name: "Herman Collins",
        image: Herman_Collins,
        linkedIn: "https://www.linkedin.com/in/herman-collins-422385/",
    },
]

export const AWARDS = [
    {
        id: "1",
        icon: CiscoIcon,
        heading: "Cisco LaunchPad Accelerator",
        body: "In May 2018, we participated in Cisco LaunchPad Cohort 3 and were awarded a grant as one of the most promising startups."
    },
    {
        id: "2",
        icon: SamsungIcon,
        heading: "Samsung Accelerator Program",
        body: "In August 2018, we won the prestigious Global Samsung Accelerator Program, becoming the only company invited by Samsung to collaborate with their global team in South Korea."
    },
    {
        id: "3",
        icon: Mass_challengeIcon,
        heading: "Mass Challenge , Israel",
        body: "In August 2018, we were honored as winners of MassChallenge Israel, receiving a grant for being one of the most promising startups addressing the critical issue of data privacy."
    },
    {
        id: "4",
        icon: IEEE_icon,
        heading: "Best Papers Awarded at IEEE",
        body: "Our research papers: “Aforonto: A six hop peer to peer network of a Zillion of Nodes”, “Bristrita: Namespace and Metadata Distribution in Large Scale Distributed Cloud Storage Systems”, “Julunga: A New Large Scale Distributed Read-Write File Storage System for Cloud Computing Environments” are awarded as Best papers by IEEE .  "
    },
]

export const GARNTER_CARD = [
{
    year: "2023",
    gartnerText: "Gartner Hype Cycles 2023 Report has recognised Ziroh Labs as a Sample Vendor in 7 categories.",
    categories:[
        {
            text: "Hype Cycle for Block Chain and Web3, Retail Central Bank Digital Currency",
        },
        {
            text: "Hype Cycle for Privacy, Homomorphic Encryption",
        },
        {
            text: "Hype Cycle for Privacy, Secure Multiparty Computation",
        },
        {
            text: "Hype Cycle for Data Security, Homomorphic Encryption",
        },
        {
            text: "Hype Cycle for Data Security, Secure Multiparty Computation",
        },
        {
            text: "Hype Cycle for Emerging Technologies in Banking, Homomorphic Encryption",
        },
        {
            text: "Hype Cycle for Emerging Technologies, Homomorphic Encryption",
        },
    ]

},
 {
    year: "2021",
    gartnerText: "Gartner Hype Cycles 2021 Report has recognized Ziroh Labs as a Sample Vendor in 3 categories.",
    categories: [
        {
            text: "Encrypted computing, FHE",
        },
        {
            text: "Secure Computing SMPC",
        },
        {
            text: "Digital Banking Transformation",
        },
    ]

}
]