import React from 'react';
import logo from './logo.svg';
import './App.scss';
import Routing from "./App.routing";
import useAppHook from "./App.hook";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useDeviceStore } from "./StateManager/DeviceStore";
import { DEVICE } from "./_shared/constants/constants";

function App() {
  const { theme } = useAppHook();
  const {device} = useDeviceStore()
  return (
    <div className={`App ${theme}`}>
    <Routing />
    <ToastContainer
      position={device === DEVICE.MOBILE ? "bottom-center": 'bottom-left'}
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      // @ts-ignore
      theme={theme}
    />
  </div>
  );
}

export default App;
