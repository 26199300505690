import React, { useState } from 'react';
import { useOsStore } from '../../../StateManager/OsStore';
import { useThemeStore } from '../../../StateManager/ThemeStore';
import { PLATFORM, THEME } from '../../constants/constants';
import { ROUTES } from '../../constants/routes';

const useNavbarHook = () => {

  const { theme, setTheme } = useThemeStore();
  const { os, setOs } = useOsStore();
  const [isSideMenu, setIsSideMenu] = useState(false);

  function handleThemetoggle() {

    if (theme == THEME.LIGHT) {
      setTheme(THEME.DARK)
      localStorage.setItem("Theme", THEME.DARK);
    }
    else {
      setTheme(THEME.LIGHT)
      localStorage.setItem("Theme", THEME.LIGHT)
    }
  }

  function handleHamClick() {
    if (isSideMenu) {
      setIsSideMenu(false)
    }
    else {
      setIsSideMenu(true)
    }
    // setIsSideMenu(!isSideMenu)
  }

  function closeSideMenu() {
    setIsSideMenu(!isSideMenu);
  
  }

  return {
    theme, setTheme,
    os, setOs,
    handleThemetoggle,
    isSideMenu, setIsSideMenu,
    handleHamClick, 
    closeSideMenu,
  }
}

export default useNavbarHook