import React from 'react'
import { Link } from 'react-router-dom';
import { ROUTES } from '../../_shared/constants/routes';
import styles from './notFound.module.scss';

function NotFound() {
  return (
    <div className={styles.notFound}>
        <p className={styles.title}>OOPS! Page not Found</p>
        <p className={styles.description}>Uh oh, we can't seem to find the page you're looking for. Try going back to the previous page or visit our <Link to={ROUTES.HOME} reloadDocument>Home Page</Link>.</p>

        <Link to={ROUTES.HOME} reloadDocument>
        <button>Go to Home</button>
        </Link>
    </div>
  )
}

export default NotFound