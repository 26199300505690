import React, { useState } from 'react'
import { ReactComponent as Sparkles } from '../../assets/svg/sparkles.svg'
import { ReactComponent as GartnerIcon } from '../../assets/svg/gartner_icon.svg'
import { motion, AnimatePresence } from 'framer-motion'
import styles from './aboutUs.module.scss'
import { GARNTER_CARD } from './aboutUsData'

interface Categories {
    text: string
}

interface GartnerCard {
    year: string,
    gartnerText: string,
    Categories: Categories[]
}


function AboutUsCard({ year, gartnerText, Categories }: GartnerCard) {
    const [hovered, setHovered] = useState(false);
    // const [ishovered, setISHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };

    const hoverVarients = {
        opened: {
            y: "0%",
            transition: {
                y: { stiffness: 50 },
                ease: "linear"
            }
        },
        closed: {
            y: "100%",
            transition: {
                y: { stiffness: 50 },
                ease: "linear"
            }
        }
    }
    return (

        <div className={styles.gartnerCard}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className={styles.gartnerTopLayer}>
                {(year == "2023") &&

                    <div className={styles.latestComponent}>
                        <div className={styles.latestChild}>
                            <Sparkles className={styles.sparkles} />
                            <p className={styles.latest}>Latest</p>
                        </div>
                    </div>
                }
                <div className={styles.gartnerLatestCard}>
                    <GartnerIcon className={styles.gartner_icon} />
                    <p className={styles.year}>{year}</p>
                </div>
                <div className={styles.gartnerBody}>
                    <p className={styles.gartnerText}>{gartnerText}</p>
                </div>
            </div>
            <AnimatePresence>
                <motion.div className={styles.gartnerOverlay}
                    variants={hoverVarients}
                    animate={hovered ? "opened" : "closed"}
                >
                    <motion.div className={styles.gartnerCardChild}
                    >
                        <p className={styles.categoriesHeading}>Categories</p>
                        <ol className={styles.categoryList}>
                            {Categories.map((data) => {
                                return (
                                    <li className={styles.categories}> {data.text}</li>
                                )
                            })}

                        </ol>
                    </motion.div>
                </motion.div>
            </AnimatePresence>
        </div>
    )
}

export default AboutUsCard