import {
	createBrowserRouter,
	RouterProvider,
	createHashRouter
} from 'react-router-dom';
import Layout from './Layout/layout';
import AboutUs from './Pages/AboutUs/aboutUs';
import Home from './Pages/Home/home';
import NotFound from './Pages/NotFound/notFound';
import { ROUTES } from './_shared/constants/routes';

const Routing = (props: any) => {
	const AppRouter = window.process ? createHashRouter : createBrowserRouter;

	const routes = AppRouter([
		{
			path: `/`,
			element: <Layout> </Layout>,

			children: [
				{
					path: ROUTES.HOME,
					element: <Home />
				},
				{
					path: ROUTES.ABOUT_US,
					element: <AboutUs />
				},

				{
					path: `/*`,
					element: <NotFound />
				}
			]
		}
	]);

	return <RouterProvider router={routes} />;
};

export default Routing;