// import * as React from 'react';
import React, { useRef, useEffect, useState } from 'react';
import styles from './navbar.component.module.scss';
import { ReactComponent as ZirohLogoDark } from '../../../assets/svg/zirohLogoDark.svg';
import { ReactComponent as Ham } from '../../../assets/svg/ham.svg';
import { ReactComponent as CloseIcon } from '../../../assets/svg/icon_close_black.svg';
import { ReactComponent as Arrow } from '../../../assets/svg/arrow-down.svg';
import { Link, Route, useLocation } from 'react-router-dom';
import Button from '../Button/button.component';
import useNavbarHook from './navbar.hook';
import { DEVICE, PLATFORM, THEME } from '../../constants/constants';
import { useDeviceStore } from '../../../StateManager/DeviceStore';
import { OutsideClickHandler } from '../OutsideClickHandler/OutsideClickHandler';
import { AnimatePresence, motion } from 'framer-motion';
import { ROUTES } from '../../constants/routes';


export default function Navbar() {

	const navbarRef = useRef<any>(null);
	const [navbarHeight, setNavbarHeight] = useState<number>(0);

	useEffect(() => {
		if (navbarRef.current) {
			const initialHeight = navbarRef.current.offsetHeight;
			setNavbarHeight(initialHeight);
			console.log('initial Navbar height:', initialHeight);

			const resizeObserver = new ResizeObserver(entries => {
				for (let entry of entries) {
					const height = entry.target.clientHeight;
					const heightInrem = height / 16;
					setNavbarHeight(heightInrem);
					document.documentElement.style.setProperty('--nav-height', `${heightInrem}rem`)
					console.log('Navbar height:', heightInrem);
				}
			});

			resizeObserver.observe(navbarRef.current);

			return () => {
				resizeObserver.disconnect();
			};
		}
	}, []);




	const {
		isSideMenu,
		handleHamClick,
		closeSideMenu,
	} = useNavbarHook();

	const { device } = useDeviceStore();

	const location = useLocation();

	useEffect(() => {
		if (isSideMenu) {
			document.body.style.overflow = "hidden"
		}
		else {
			document.body.style.overflow = "unset"
		}
	}, [isSideMenu])

	return (

		<motion.nav className={`${styles.navbar}`}

		>
			<motion.div className={styles.navbarChildContainer} ref={navbarRef}>
				<motion.div className={styles.logoContainer}>
					<Link to={ROUTES.HOME} reloadDocument>
						<ZirohLogoDark className={styles.logo} />
					</Link>
				</motion.div>

				<motion.div className={styles.navOptions}>
					{device == DEVICE.DESKTOP && (
						<motion.div className={styles.navLinks}>
							<Link
								to={ROUTES.INDIVIDUALS}
								reloadDocument
								className={styles.navOption}
								target="_blank"
							>
								Individuals
							</Link>

							<Link
								to={ROUTES.ENTERPRISE}
								reloadDocument
								className={styles.navOption}
								target="_blank"
							>
								Enterprise
							</Link>

							{/* <Link
								to={ROUTES.ABOUT_US}
								reloadDocument
								className={styles.navOption}
							>
								About Us
							</Link> */}

							<Link
								to={ROUTES.CAREERS}
								reloadDocument
								className={styles.navOption}
								target="_blank"
							>
								Careers
							</Link>

							{/* <Link
								to={ROUTES.SUPPORT}
								reloadDocument
								className={styles.navOption}
								target="_blank"
							>
								Resources & Support
							</Link> */}

						</motion.div>
					)}

					<motion.div className={styles.demo_button_container}>
						{/* <Link
							reloadDocument
							to={`${ROUTES.CONTACT_US}`}
							target='_blank'
						// className={styles.downloadButtonContainer}
						>
							<Button type='secondary' text="Contact Us" />
						</Link> */}

						{device !== DEVICE.DESKTOP && (
							<motion.div className={`${styles.hamIcon}`} onClick={handleHamClick}>
								<motion.span className={ `${styles.navbar_burger} ${isSideMenu && `${styles.burger_active1}`}`}></motion.span>
								<motion.span className={ `${styles.navbar_burger} ${isSideMenu && `${styles.burger_active2}`}`}></motion.span>
							</motion.div>
						)}

					</motion.div>
					{/* } */}
				</motion.div>
			</motion.div>

			<AnimatePresence mode='wait'>
				{isSideMenu &&
					<motion.div className={styles.sideMenuContainer}

						initial={{
							height: 0,
							// clipPath: "inset(0% 0% 100% 0%)"
						}}
						animate={{
							height: "78vh",
							willChange: "auto"
							// clipPath: "inset(0% 0% 0% 0%)"
						}}
						transition={{
							duration: .4,
							ease: "linear"
						}}
						exit={{
							height: 0,
							// clipPath: "inset(0% 0% 100% 0%)"
						}}
					>

						<motion.div className={styles.sideMenuWrapper}>
							<motion.div
								className={isSideMenu ? `${styles.sideMenu} ${styles.sideMenuActive}` : `${styles.sideMenu}`}
								>
								<motion.div className={styles.menu}>
									<hr />
									<Link
										className={styles.menuHeading}
										reloadDocument
										to={ROUTES.INDIVIDUALS}
										target="_blank"
									>
										Individuals
									</Link>

									<hr />

									<Link
										className={styles.menuHeading}
										reloadDocument
										to={ROUTES.ENTERPRISE}
										target="_blank"
									>
										Enterprise
									</Link>

									<hr />

									{/* <Link
										className={styles.menuHeading}
										reloadDocument
										to={ROUTES.ABOUT_US}
									>
										About Us
									</Link> */}
									{/* <hr /> */}
									<Link
										to={ROUTES.CAREERS}
										reloadDocument
										className={styles.menuHeading}
										target="_blank"
									>
										Careers
									</Link>
									<hr />
								</motion.div>
							</motion.div>
						</motion.div>
					</motion.div>
				}
			</AnimatePresence>

		</motion.nav >

	);
}
