import { Link } from 'react-router-dom'
import { ROUTES } from '../../_shared/constants/routes'
import styles from './home.module.scss'
import Enterprise_illustration from '../../assets/webp/enterprise_illustration.webp'
import PrivacySuite_illustration from '../../assets/webp/privacy_illustration.webp'
import HeaderBackgrund from '../../assets/webp/ziroh_BackgroundBg.webp'

function Home() {
  return (
    <div className={styles.homePageContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.header}>
          <p className={styles.heading}>
            Making Privacy <span>Stronger</span>, Enterprises <span>Safer</span>, and Intelligence <span>Simplified</span>.
          </p>
          <p className={styles.subHeading}>
            Delivering privacy-first solutions for individuals and enterprises alongside an Affordable, CPU-based AI platform designed to be environmentally friendly and accessible to everyone.
          </p>
          <div className={styles.learnMoreBtnContainer}>
            {/* <Link to={ROUTES.ABOUT_US} reloadDocument>
              <button className={styles.learnMoreBtn}>Learn More About Us</button>
            </Link> */}
          </div>
        </div>
        <img src={HeaderBackgrund} className={styles.backgroundImg} />
      </div>

      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <div className={styles.cardTextcontainer}>
            <div className={styles.cardHeader}>
              <p className={styles.title}>Ziroh for Enterprise</p>
              <p className={styles.subTitle}>Ziroh Enterprise provides an Encryption-as-a-Service solution to safeguard enterprise data across various platforms and database infrastructures. Our platform equips businesses with tools to protect sensitive data from cyber threats and ensures compliance with data protection regulations.</p>
            </div>
            <Link to={ROUTES.ENTERPRISE} target="_blank">
              <button className={styles.cardBtn}>Learn About Our Enterprise Solutions</button>
            </Link>
          </div>
          <div className={styles.cardImgContainer}>
            <img src={Enterprise_illustration} />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardTextcontainer}>
            <div className={styles.cardHeader}>
              <p className={styles.title}>Zunu Privacy <span>Suite</span></p>
              <p className={styles.subTitle}>Privacy Perfected—with Zunu
                <br></br>
                Keeps your digital world private, secure, and protected on devices, in the cloud and everywhere beyond.
              </p>
            </div>
            <Link to={ROUTES.INDIVIDUALS} target="_blank">
              <button className={styles.cardBtn}>Explore Zunu Privacy Suite</button>
            </Link>
          </div>
          <div className={styles.cardImgContainer}>
            <img src={PrivacySuite_illustration} />
          </div>
        </div>

      </div>
    </div>
  )
}

export default Home